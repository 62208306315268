import React from 'react'
import 'twin.macro'

export default function FooterPw() {
    return (
        <div tw="relative bg-gray-50 overflow-hidden">
            <div tw="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full" aria-hidden="true">
                <div tw="relative h-full max-w-7xl mx-auto">
                <svg tw="absolute right-0 transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2" width="404" height="784" fill="none" viewBox="0 0 404 784">
                    <defs>
                    <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                        <rect x="0" y="0" width="4" height="4" tw="text-gray-200" fill="currentColor" />
                    </pattern>
                    </defs>
                    <rect width="404" height="784" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
                </svg>
                <svg tw="absolute left-0 transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2" width="404" height="784" fill="none" viewBox="0 0 404 784">
                    <defs>
                    <pattern id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                        <rect x="0" y="0" width="4" height="4" tw="text-gray-200" fill="currentColor" />
                    </pattern>
                    </defs>
                    <rect width="404" height="784" fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)" />
                </svg>
                </div>
            </div>

            <div tw="relative pt-6 pb-4 sm:pb-8">
                <main tw="mt-6 mx-auto max-w-7xl px-4 sm:mt-8">
                <div tw="text-center">
                    <h1 tw="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                    <span tw="block xl:inline">Say Hello!</span>
                    <span tw="block text-secondary-900 xl:inline"><br />LET’S START SOMETHING NEW.</span>
                    </h1>
                    <p tw="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">Contact us for a free web analysis or to discuss your next venture over a cup of coffee!</p>
                </div>
                </main>
            </div>
            </div>

    )
}
